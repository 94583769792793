.navbar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  transition: ease-in duration 0.3s;
}

.navbar-nav {
  max-width: 1700px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 2.5rem;
}

.navbar-links {
  display: flex;
  font-size: 1.2rem; /* 36px */
}

.navbar-links li {
  padding: 1rem;
}

.navbar-nav h4 {
  font-size: 1.8rem; /* 36px */
  line-height: 1, 8rem; /* 40px */
  text-transform: uppercase;
  letter-spacing: 2px;
}

.navbar-nav h4 span {
  font-size: 1.8rem;
  color: transparent;
  /* -webkit-text-fill-color: var(--white-text); */
  -webkit-text-stroke-width: 3px;
  -webkit-text-fill-color: var(--color-gold);
  -webkit-text-stroke-color: var(--white-text);
  -webkit-text-stroke-width: 0.03rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.navbar-nav a:hover {
  color: var(--color-gold);
}

.mobile-btn {
  z-index: 20;
  display: none;
}

.mobile-btn svg {
  height: 30px;
  width: 30px;
  margin-right: 2rem;
  margin-top: 1.4rem;
  color: var(--white-text);
}

.mobile-menu__open {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9));
  text-align: center;
  transition: ease-in 0.3s;
}

.mobile-menu__close {
  position: absolute;
  top: 0;
  left: -100%;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: black;
  text-align: center;
  transition: ease-in 0.3s;
}

.mobile-menu__open li {
  font-size: 2.25rem;
  padding: 1rem;
}

.mobile-menu__links li:hover {
  color: var(--color-gold);
}

/* Photo Gallery */
.photogallery {
  min-height: 100%;
  width: 100%;
  padding: 100px 0;
}

.photogallery .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  box-shadow: 0px 0px 61px 7px var(--shade-gray);
  padding: 50px 20px;
  border-radius: 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header h1 {
  font-size: 2.5rem;
  font-weight: 900;
  letter-spacing: 1px;
  font-family: var(--Barlow-Condensed);
  margin-bottom: 10px;
}

.gallery {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 20px;
  gap: 20px;
}

.item {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  transition: 0.3s ease box-shadow;
  cursor: pointer;
}

.item:hover {
  box-shadow: 0px 0px 20px 3px var(--dark-shade-brown);
}

.photogallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item .modal-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item .__react_modal_image__medium_img {
  max-width: 700px;
  max-height: 700px;
  background-color: black;
}

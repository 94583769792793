#services {
    background-color: white;
  }
  
  .list {
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  }
  
  .card {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: var(--primary-color);
    padding-bottom: 8rem;
    /* background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.9)); */
  }
  
  .bg-color-2 {
    background-color: var(--color-hsl-brown);
  }
  
  .card-image .img_bg-color-2 {
    background-color: var(--orange-brown);
  }
  
  .card-image {
    height: 190px;
    position: relative;
  }
  
  .circle {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    top: 10px;
    left: calc(50% - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-color);
  }
  
  .circle img {
    height: 80%;
    width: 80%;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .card-content {
    /* padding: 1rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .card-content h3 {
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 2rem;
  }
  
  .card-content p {
    max-width: 90%;
    text-align: center;
  }
  
  .card-content a {
    display: inline-block;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    margin-top: 1rem;
  }
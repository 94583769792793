#hero {
    width: 100%;
    height: 100vh;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url("../../assets/newImage1.png") no-repeat center / cover;
  }
  
  .container {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .container .info {
    position: relative;
  }
  
  .container .info small {
    font-size: 1rem;
  }
  .container .info h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    max-width: 60%;
  }
  
  .container .info::before {
    content: "";
    display: block;
    width: 2px;
    height: 230px;
    background-color: var(--color-gold);
    position: absolute;
    right: 0;
    left: -40px;
    top: 20px;
  }
  
  .container .info p {
    font-size: 1.2rem;
    max-width: 70%;
  }
  
  .container .info a {
    text-decoration: none;
    display: inline-block;
    background-color: var(--orange-brown);
    border-radius: 4px;
    font-size: 1.8rem;
    padding: 0.5em 2em;
    margin-top: 2rem;
    color: var(--white-text);
  }
  
  .container .info a:hover {
    scale: 1.05;
    background-color: var(--primary-color);
  }
  
  .container .info a:active {
    scale: 1;
  }
  
  .container .info span {
    color: transparent;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    text-transform: uppercase;
  }
  
  #services {
    background-color: white;
  }
  
  .list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  
  .card {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: var(--primary-color);
    padding-bottom: 8rem;
    /* background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.9)); */
  }
  
  .bg-color-2 {
    background-color: var(--color-hsl-brown);
  }
  
  .card-image .img_bg-color-2 {
    background-color: var(--orange-brown);
  }
  
  .card-image {
    height: 190px;
    position: relative;
  }
  
  .circle {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    top: -60px;
    left: calc(50% - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-color);
  }
  
  .circle img {
    height: 80%;
    width: 80%;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .card-content {
    /* padding: 1rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .card-content h3 {
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 2rem;
  }
  
  .card-content p {
    max-width: 90%;
    text-align: center;
  }
  
  .card-content a {
    display: inline-block;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    margin-top: 1rem;
  }

  /* ----Modal---- */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }
  
  .flex-container iframe {
    max-width: 80%;
    height: 700px;
    box-shadow: 0px 0px 61px 7px var(--shade-gray);
    padding: 50px 20px;
    border-radius: 20px;
  }
  
  /* IFrame Modal */
  
  .book-btn {
    position: fixed;
    display: inline-block;
    padding: 3px 6px;
    top: 25%;
    border: none;
    background-color: var(--orange-brown);
    color:var(--white-text);
    font-size: 1.2rem;
    cursor: pointer;
    z-index: 2;
  
  }
  .book-btn::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: var(--white-text);
    transition: all 0.3s ease-in-out;
  }
  
  .book-btn:hover {
    scale: 1.05;
  }
  .book-btn:active {
    scale: 1.05;
  }
  
  .book-btn:hover::after {
    left: 0;
    width: 100%;
  }
  
  .book-btn::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: var(--white-text);
    transition: all 0.3s ease-in-out;
  }
  
  .book-btn:hover::before {
    left: 100%;
  }
  
  .modal {
    display: none;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    position: relative;
  }
  
  .close {
    position: absolute;
    top: 0;
    right: 0;
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
    padding: 5px 10px;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  iframe {
    width: 100%;
    height: 500px;
  }
  